import * as React from 'react';
import Navbar from "../components/nav/side/Navbar";
import {Box, Grid2, AppBar, Toolbar, Typography} from '@mui/material'
import {useTheme} from "@mui/material/styles";

interface Props {
    content: React.ReactNode,
    navSelection: string,
    setNavSelection: Function
}


export default function MainLayout(props: Props) {
    return (
        <Box sx={{display: 'flex', height: '100vh'}}>
            <Navbar navSelection={props.navSelection} setNavSelection={props.setNavSelection}/>

            {/*Toolbar*/}
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                {/* Header */}
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{props.navSelection}</Typography>
                    </Toolbar>
                </AppBar>

                {/*Main Content*/}
                <Box sx={{p: 2, flexGrow: 1, backgroundColor: 'background.default'}}>
                    {props.content}
                </Box>
            </Box>
        </Box>

    );
}